import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import Header from "../screens/regional-pcr-testing/header"
import Hero from "../screens/regional-pcr-testing/hero-arizona-1"
import Locator from "../screens/regional-pcr-testing/maps/arizona-1"
import Device from "../screens/regional-pcr-testing/sti-device-arizona-1"
import Footer from "../screens/regional-pcr-testing/footer"

const ArizonaOne = () => {
	return (
		<React.Fragment>
			<Header />
			<Hero />
			<Locator />
			<Device />
			<Footer />
		</React.Fragment>
	)
}
export default ArizonaOne


export const Head = () => (
  <Seo
		title="Regional PCR Testing | Arizona"
		description="Why wait days for STI Results?"
  />
)
