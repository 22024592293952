import React from "react"

// Libraries
import { Link } from "gatsby"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

// Icons
import LinkedIn from "assets/icons/logo-linked-in.inline.svg"
import Twitter from "assets/icons/logo-twitter.inline.svg"
import Facebook from "assets/icons/logo-facebook.inline.svg"
import Logo from "assets/icons/logo-tm.inline.svg"

const StyledContainer = styled(Container)`
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
  max-width: 1200px;
`

const StyledMenu = styled.nav`
  width: 100vw;
  height: 60px;
  top:0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-color: ${colors.purpleDark};
  border-bottom-width: 0;
  z-index: 900;
  overflow: visible;

  ${breakpoint.medium`
    height: 70px;
  `}

 .menu__first-section {
	  margin-top: 0;
    display: block;
    align-items: center;

    .menu__logo {
      width: 240px;
      position: relative;
      z-index: 901;
	    margin-left: -7px;
	  
      ${breakpoint.medium`
        width: 240px;
	      margin-left: -11px;
      `}

      svg {
        * {
          transition: all 0.3s ease;
          fill: ${colors.white};
        }
      }
    }
}

  .social__column {
    width: auto;
    
 .social-links {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

    .social-link {
      margin-right: 16px;
      display: inline-block;

      &:last-child {
        margin-right: 0;
      }

      a {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${colors.purpleLight};
        border-radius: 50%;

        &:hover {
          background-color: ${colors.grayDark};
        }
      }
    }
 
   }
 
`

class Menu extends React.Component {

  componentDidMount = () => {
		document.querySelector("body").style.paddingTop="0px"
		}


  render = () => (
    <StyledMenu>
      <StyledContainer>      
			<div className="menu__first-section">
				<Link to="/" className="menu__logo" aria-label="Visby Medical">
				<Logo />
			  </Link>
			</div>
        <div className="social__column social__column--info">
          <ul className="social-links">
            <li className="social-link">
              <a
                href="https://www.linkedin.com/company/visbymedical/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              >
                <LinkedIn />
              </a>
            </li>
            <li className="social-link">
              <a
                href="https://twitter.com/visbymedical"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
              >
                <Twitter />
              </a>
            </li>
            <li className="social-link">
              <a
                href="https://www.facebook.com/visbymedical/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <Facebook />
              </a>
            </li>
          </ul>
		</div>
      </StyledContainer>
    </StyledMenu>
  )
}

export default Menu
