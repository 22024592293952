import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  padding: 0 20px;
`

const StyledZipCode = styled.section`
  padding: 48px 0 24px;
  background-color: ${colors.purpleDark};
    
  ${breakpoint.medium`
    padding: 80px 0 40px;
  `}

    h2 {
      padding-bottom: 2.5rem;
      color: ${colors.white};
    }
    p {
      padding-bottom: 0.5rem;
      color: ${colors.white};
    }

  
     a {
      color: ${colors.orange};

      &:hover {
        color: ${colors.purpleDark};
      }
    }
 }

.formTitle {
	width: 100%;
	text-align: center;
}

.form-map {
	width: 100%;
	max-width: 1200px;
	margin: 0px auto;
	text-align: center;
	
	iframe {
		width: 100%;
		max-width: 450px;
		height: 300px;	

		${breakpoint.xs`
			max-width: 600px;
			height: 450px;	
		`}

		${breakpoint.small`
			max-width: 900px;
			height: 600px;	
		`}

		${breakpoint.medium`
			max-width: 1200px;
			height: 900px;	
		`}

	}
}
`

const ZipCodeForm = () => {

  return (
    <StyledZipCode>
      <StyledContainer>
      <div className="formTitle" id="map">
      <h2>Testing locator for Phoenix, AZ</h2>
      </div>
        <div className="form-map">
          <iframe src="https://www.google.com/maps/d/embed?mid=1Y9oemr41VJu7aauFNKc7k3voeRQi37Xl&ehbc=2E312F" title="Testing Locator for Phoenix, AZ"></iframe>
        </div>
      </StyledContainer>
    </StyledZipCode>
  )
}

export default ZipCodeForm
